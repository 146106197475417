<template>
  <main>
    <div class="contenair">
      <div class="banner2" :style="`background-image:url(${banner_link})`">
        <h1>Promodev : L’énergie à votre service</h1>
      </div>
    <div class="box">
    
    </div>
   </div> 
  </main>
</template>
<script>
export default {
  name: "mentions",
  data() {
    return {
      banner_link: this.$root.configOp.datas.banner2,       

    };
  },
};
</script>